.PortfolioPage {
  margin: auto;

  .HeaderTile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1.5rem;
    background-image: linear-gradient(150deg, #558 10%, #112);
  }

  .ContactsTile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(120deg, #112 50%, #558);
    padding: 1.5rem;

    p {
      transition: transform .3s ease-in-out;
    }

    .icon {
      width: 3rem;
      height: 3rem;
      margin: .75rem 0;
      pointer-events: none;
      transition: transform .5s ease-in-out, fill 5s ease-in-out;

      g {
        border-radius: 50%;
        pointer-events: all;

        path {
          transition: fill .1s ease-in-out;
          fill: white;
        }

        circle, rect {
          fill: transparent;
        }
      }
    }

    .link:hover {
      //color: #ccc;

      p {
        transform: rotate(-4deg);
        transform-origin: left center;
      }

      .icon {
        animation: rotate infinite 1.5s ease-in-out;
        path {
          fill: #ffe0f6;
        }
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
