.UrlTile {
  padding: 4rem 2rem 5rem;
  height: 100%;
  width: 100%;
  transition: .2s ease-out;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.02);
    box-shadow: #0006 2px 2px 15px;
  }

  .background {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;

    &.gradient-overlay {
      animation: blink ease-in-out infinite alternate-reverse;
    }

    &.filter {
      opacity: 0.15;
      background-color: black;
    }

    @keyframes blink {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .text-container {
    position: relative;
    z-index: 10;

    h2 {
      margin-top: unset;
    }
  }
}
